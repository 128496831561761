import { FC } from "react";
import Slider from "react-slick";
import { GoQuote } from "react-icons/go";
import { REVIEWS } from "../../StaticData/ProductSliderData";
import classes from "./ReviewSlider.module.scss";

interface IReviewSlider {
	_id: string;
	reviewText: string;
	ratingNumber: string;
	ratingStar: string;
	clientName: string;
	clientWebsite: string;
}

const ReviewSlider: FC = () => {
	const settings = {
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 8000,
	};
	return (
		<section className={classes.reviewSlider}>
			<Slider {...settings}>
				{REVIEWS.map(
					({
						_id,
						reviewText,
						ratingNumber,
						ratingStar,
						clientName,
						clientWebsite,
					}: IReviewSlider) => (
						<div className={classes.sliderCard} key={_id}>
							<div className={classes.cardContainer}>
								<div className={classes.cardQuotes}>
									<GoQuote style={{ color: "#fff", fontSize: "92px" }} />
								</div>
								<div className={classes.textHolder}>
									<p
										className={classes.reviewText}
										dangerouslySetInnerHTML={{ __html: reviewText }}
									></p>

									<div className={classes.clientInfo}>
										<div className={classes.clientRating}>
											<span className={classes.ratingNumber}>
												{ratingNumber}
											</span>
											<img
												src={ratingStar}
												alt="image"
												className={classes.ratingStars}
											/>
										</div>

										<p className={classes.clientName}>{clientName}</p>
										{clientWebsite && (
											<p className="client-website">{clientWebsite}</p>
										)}
									</div>
								</div>
							</div>
						</div>
					),
				)}
			</Slider>
		</section>
	);
};

export default ReviewSlider;
