import { makeId } from "./makeId";
// For Product Slider
export const PRODUCTS = [
	{
		_id: `${makeId(5)}`,
		link: "/resources/what-is-a-reseller-license",
		image: "/img/resources/image6.jpg",
		buttonText: "Learn More",
		downloadLink: "/What_is_a_Reseller_License.pdf",
		downloadIcon: "/img/pdf-icon.png",
		memberName: "What is a Reseller License?",
		descriptionImage: "/img/resources/image6.jpg",
		descriptionName: "What is a Reseller License?",
		descriptionText: `Whether you are starting an online or brick and mortar
		retail store (this includes selling on websites like
		Facebook and eBay), you will need to have a reseller
		license. This license allows your business to not pay any
		sales tax on wholesale products purchased for the purpose
		of reselling to your customers.`,
	},
	{
		_id: `${makeId(5)}`,
		link: "/resources/risks-of-purchasing-without-a-license",
		image: "/img/resources/image7.jpg",
		buttonText: "Learn More",
		downloadLink: "/Risks_of_Purchasing_Without_Reseller_License.pdf",
		downloadIcon: "/img/pdf-icon.png",
		memberName: "Risks of Purchasing Without a License",
		descriptionImage: "/img/resources/image7.jpg",
		descriptionName: "Risks of Purchasing Without a License",
		descriptionText: `Before we talk about the risks of purchasing without a
    Reseller License, some of you may wonder, “What is a
    Reseller License?” If this is you, make sure you read our
    article, “What is a Reseller License,” first. You will
    understand the rest of this article much better.`,
	},
	{
		_id: `${makeId(5)}`,
		link: "/resources/manifested",
		image: "/img/resources/image5.jpg",
		buttonText: "Learn More",
		downloadLink: "/Manifested_vs_Un-Manifested.pdf",
		downloadIcon: "/img/pdf-icon.png",
		memberName: "Manifested or Unmanifested?",
		descriptionImage: "/img/resources/image5.jpg",
		descriptionName: "Manifested or Unmanifested?",
		descriptionText: `This is a regular discussion topic in the various forums.
    Should I only buy manifested loads or is unmanifested
    worth it? Honestly, to each their own. But, I have a few
    thoughts which I wanted to put out here. First, everything
    said below is 100% dependent upon whether or not you are
    working with a reputable seller. If you are working with
    an underhanded seller then no guidance can be consistently
    reliable.`,
	},
	{
		_id: `${makeId(5)}`,
		link: "/resources/avoid-risky-payment-methods",
		image: "/img/resources/image2.jpg",
		buttonText: "Learn More",
		downloadLink: "/Avoid_Risky_Payment_Methods.pdf",
		downloadIcon: "/img/pdf-icon.png",
		memberName: "Avoid Risky Payment Methods",
		descriptionImage: "/img/resources/image2.jpg",
		descriptionName: "Avoid Risky Payment Methods",
		descriptionText: `Use secure payment method to avoid losing your money or
    product form scammers.`,
	},
	{
		_id: `${makeId(5)}`,
		link: "/resources/how-to-recieve-a-truck-load",
		image: "/img/resources/image4.jpg",
		buttonText: "Learn More",
		downloadLink: "/What_is_a_Reseller_License.pdf",
		downloadIcon: "/img/pdf-icon.png",
		memberName: "How to Receive a Truckload",
		descriptionImage: "/img/resources/image4.jpg",
		descriptionName: "How to Receive a Truckload",
		descriptionText: `You are new to the warehousing and freight business. You
    are excited to establish your new location and to receive
    your first truck. You wait with eager anticipation like a
    five-year-old on Christmas with various questions running
    rampant in your head!`,
	},
];

// Review Slider
export const REVIEWS = [
	{
		_id: `${makeId(5)}`,
		reviewText: `I buy 5-6 truckloads a week from BuyLow.They are great people to
	work with, they are always available to answer any questions,
	and they go out of their way to find what you<span>&#39;</span>re looking for!`,
		ratingNumber: "5.0",
		ratingStar: "/img/5-stars.png",
		clientName: "Mat",
		clientWebsite: "",
	},
	{
		_id: `${makeId(5)}`,
		reviewText: `THESE GUYS ARE THE MOST EDUCATED AND HONEST IN THE BUSINESS!!! I<span>&#39;</span>VE BEEN DOING THIS FOR 5 years and have never
		found someone that I can count on and trust with my money more
		than these guys!`,
		ratingNumber: "5.0",
		ratingStar: "/img/5-stars.png",
		clientName: "John",
		clientWebsite: "",
	},
	{
		_id: `${makeId(5)}`,
		reviewText: `I love doing business with BuyLow Warehouse. They always return my
		messages and work hard to get me the products I need to succeed in
		my business. They go above and beyond to satisfy their customers
		and bring them the best products at the best prices. I HIGHLY
		recommend the BuyLow Team!`,
		ratingNumber: "5.0",
		ratingStar: "/img/5-stars.png",
		clientName: "Rhonda",
		clientWebsite: "",
	},
	{
		_id: `${makeId(5)}`,
		reviewText: `I highly recommend BuyLow if you<span>&#39;</span>re interested in
		this business and looking to find brokers who give it everything.
		The communication throughout the whole process is A+ which in this
		industry is huge. They are always available to answer any
		questions you might have. After growing in this business, I model
		the way I handle relationships with new customers the same way
		they do. Thanks to all at BuyLow.`,
		ratingNumber: "5.0",
		ratingStar: "/img/5-stars.png",
		clientName: "Ryan",
		clientWebsite: "",
	},
	{
		_id: `${makeId(5)}`,
		reviewText: `	The BuyLow Team is some of the best in the business. Matt spent a
		lot of time on the phone with me when we were just opening our bin
		store and helped me navigate some of the <span>&#39;</span>
		in<span>&#39;</span>s and outs
		<span>&#39;</span> of the industry. Every single load I have
		gotten from them has exceeded what I was expecting. I received my
		12th or 13th truck from them today. It, too, was better than I was
		expecting`,
		ratingNumber: "5.0",
		ratingStar: "/img/5-stars.png",
		clientName: "Jerry",
		clientWebsite: "",
	},
];
